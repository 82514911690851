.content {
    width: 100%;
    display: flex;
    min-height: 900px;
    margin: 10px 0;
    padding: 20px;
    background-color: #fff;
    position: relative;
}

.container {
    max-width: 1100px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
}