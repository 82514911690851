.friend_item {
    height: 80px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.friend_item img {
    border-radius: 100%;
    border: 2px solid #ccc;
    margin: 0 auto;
    overflow: hidden;
    width: 50px;
    height: 50px;
    background-color: #3f4257;
}

.friend_item span {
    font-size: 0.8em;
    color: #9a9fbf;
    display: inline-block;
    margin-top: 4px;
}
