.footer {
    height: 70px;
    background-color: #3f4257;
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footer .page_title {
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .page_title > * {
    color: #fff;
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
}