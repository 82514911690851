.content {
    width: 100%;
    display: flex;
    min-height: 900px;
    flex-flow: column;
    margin: 10px 0;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.container {
    max-width: 1100px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
}

.author_thumb img {
    border-radius: 100%;
    overflow: hidden;
    max-width: unset;
    display: block;
    max-width: 100%;
}

.notification_list {
    width: 100%;
}

 .author_thumb {
    height: 50px;
    width: 50px;
}

.notification_event {
    padding-left: 15px;
    width: 30%;
    display: flex;
    flex-flow: column;
}

.notification_content {
    padding-left: 15px;
    width: 60%;
    display: flex;
    flex-flow: column;
}

.notification_friend {
    color: #282c34;
    text-decoration: none;
    padding: 0 0 4px 0;
}

.chat_message_item {
    font-size: 0.7em;
}

.notification_icon {
    color: #d7d9e5;
    fill: #d7d9e5;
    width: 10%;
    text-align: revert;
    display: flex;
    justify-content: flex-end;
}

.accept_request {
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem;
    font-size: 0.688rem;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    background-color: #38a9ff;
    color: #fff;
    fill: #fff;
}

.accept_request:disabled {
    background-color: #888;
}

.item_friend {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e6ecf5;
}


.get_users {
    display: inline-block;
    font-weight: 700;
    margin: 20px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .812rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: all .15s ease-in-out;
    background-color: #ff5e3a;
    border-color: #ff5e3a;
    color: #fff;
    cursor: pointer;

}

.get_users:hover {
    background-color: #ff763a;
    border-color: #ff763a;
    opacity: 1;
}
