.content_bg_wrap {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    background-position: 0 0;
    background-repeat: repeat;
    -webkit-animation: 50s linear infinite;
    animation: 50s linear infinite;
    background-size: auto;
    align-items: center;
    justify-content: center;
}

.content_bg_wrap:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 150, 210, 0.90);
}

.welcome_text {
    position: relative;
    z-index: 10;
    color: #ffffff;
}
