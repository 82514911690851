.content {
    width: 100%;
    display: flex;
    min-height: 340px;
    margin: 10px 0;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.container {
    max-width: 1100px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
}

.music_from {
    width: 100%;
}

.music_from h3 {
    color: #282c34;
    font-weight: bold;
    font-size: 1em;
    margin: 0px;
    text-align: center;
    padding: 20px;
}