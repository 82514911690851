html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  min-height: 100%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: column;
  background-color: #edf2f6;
  position: relative;
  padding-bottom: 100px;
}

.App * {
  box-sizing: border-box;
}

.container {
  max-width: 1100px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
}








